import { assets } from '@wff/mithra/assets';
import { formatPrice } from '@wff/mithra/lib/formatPrice';
import { Property, FileT } from '@wff/types';
import { clx } from '@wff/ui/css';

type PropertyCardProps = {
  property: Property;
  coverImage: FileT | undefined;
  badge?: string;
  secondaryBadge?: string;
  amenitiesDetails: { icon: string; title: string; description: string }[];
  onClick: () => void;
  sm?: boolean;
};

export const PropertyCard = (props: PropertyCardProps) => {
  const {
    sm = false, // Default to false for regular size
    property,
    coverImage,
    badge,
    secondaryBadge,
    amenitiesDetails,
    onClick,
  } = props;

  const price = property?.rentMonthly || 'unknown';
  const bedrooms = property?.numberOfBedrooms || 1;
  const bathrooms = property?.numberOfBathrooms || 1;
  const flatType = property.flatType || '';
  const area = property?.areaName || '';
  const city = property?.city || '';

  return (
    <div
      onClick={onClick}
      className={`card hover:cursor-pointer bg-base-100 shadow-xl`}
    >
      <figure>
        <div className={`left-4 flex flex-col gap-y-2 top-4 absolute`}>
          {badge && (
            <div
              className={`badge rounded-[0.625rem] h-fit font-bold py-1 px-4 badge-primary ${sm ? 'text-xs' : 'text-base'} text-white`}
            >
              {badge}
            </div>
          )}
          {secondaryBadge && (
            <div
              className={`badge rounded-[0.625rem] h-fit font-bold py-1 px-4 badge-secondary ${sm ? 'text-xs' : 'text-base'} text-white`}
            >
              {secondaryBadge}
            </div>
          )}
        </div>
        <img
          className={clx('object-cover w-full', sm && 'h-44')}
          src={coverImage?.url || ''}
          onError={(e) => {
            e.currentTarget.src = assets.defaultPropertyImage;
          }}
          alt={property.title}
        />
      </figure>
      <div
        className={clx('card-body', sm && 'h-32 justify-between gap-0 py-1')}
      >
        <p
          className={`card-title ${sm ? 'text-sm' : 'text-lg'} font-medium text-neutral`}
        >
          {flatType}
        </p>
        <p className={`${sm ? 'text-base' : 'text-2xl'} font-bold`}>
          {formatPrice(price, property.rentCurrency)}{' '}
          <span
            className={`${sm ? 'font-medium text-sm' : 'font-medium text-lg'} text-neutral`}
          >
            /month
          </span>
        </p>
        <h2
          className={`${sm ? 'text-base' : 'text-xl'} font-medium overflow-hidden text-ellipsis whitespace-nowrap`}
        >
          {area ? `${area}, ${city}` : city}
        </h2>
        <div
          className={clx(
            'card-actions mt-1 gap-y-0 gap-x-3',
            'justify-center items-center w-full',
            'grid grid-rows-auto',
            'grid-cols-[repeat(auto-fit,minmax(40px,auto))]'
          )}
        >
          <div
            className={clx(
              'flex w-full justify-center items-center',
              bedrooms !== 1 && 'gap-x-2'
            )}
          >
            <img
              src={assets.bed}
              title={'Bedroom'}
              alt="Bedroom"
              className={sm ? 'w-7 h-7' : 'w-9 h-9'}
            />
            <span className={sm ? 'text-sm' : 'text-lg'}>
              {bedrooms === 1 ? null : bedrooms}
            </span>
          </div>
          <div
            className={clx(
              'flex w-full justify-center items-center',
              bathrooms !== 1 && 'gap-x-2'
            )}
          >
            <img
              src={assets.bathroom}
              title={'Bathroom'}
              alt="Bathroom"
              className={sm ? 'w-9 h-9' : 'w-12 h-12'}
            />
            <span className={sm ? 'text-sm' : 'text-lg'}>
              {bathrooms === 1 ? null : bathrooms}
            </span>
          </div>

          {amenitiesDetails?.map((amenityDetails, index) => (
            <div className="flex w-full justify-center items-center">
              <img
                title={amenityDetails.title}
                key={index}
                className={`${sm ? 'w-4 h-4' : 'w-10 h-10'}`}
                src={amenityDetails.icon}
                alt={amenityDetails.title}
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
